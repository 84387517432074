import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Ubuntu',
            'sans-serif'
          ].join(','),
        },
    palette: {
      type: 'dark',
      primary: {
        main: '#D5392F',
      },
      secondary: {
        main: '#1E6DA7',
      },
      error: {
        main: '#D5392F',
      },
      background: {
        default: '#231E20',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          color: 'white',
          backgroundColor: '#999',
        },
      },
    },
});

export default theme;