// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-geek-js": () => import("./../../../src/pages/geek.js" /* webpackChunkName: "component---src-pages-geek-js" */),
  "component---src-pages-happiness-js": () => import("./../../../src/pages/happiness.js" /* webpackChunkName: "component---src-pages-happiness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-nature-js": () => import("./../../../src/pages/nature.js" /* webpackChunkName: "component---src-pages-nature-js" */),
  "component---src-pages-original-js": () => import("./../../../src/pages/original.js" /* webpackChunkName: "component---src-pages-original-js" */)
}

